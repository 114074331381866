<!--
File: Districts.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <RegionsDropdown v-model="selectedRegion" />
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}"
          :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null">
            {{ item[header.value] }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseTableMixin from '@/mixins/BaseTableMixin'
import { RegionsDropdown } from '@/pages/Components'
import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
import SearchInput from "@/pages/Components/SearchInput.vue"

export default {
  name: 'districts-list',
  mixins: [BaseTableMixin],

  components: {
    RegionsDropdown,
    BaseDataTable,
    SearchInput,
  },

  data() {
    return {
      // Переопределяем только специфичные для компонента данные
      formName: 'Districts',
      idFieldName: 'district_id',
      propsToSearch: ['region_description', 'description', 'type', 'notes'],
      currentSort: 'region_description',

      historyMapping: {
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        pagination: this.pagination,
        searchQuery: ''
      }
    }
  },

  methods: {
    ...mapActions({
      loadList: 'LOAD_DISTRICT_LIST',
    }),
  },

  computed: {
    ...mapState({
      districtsList: (state) => state.RoadNetwork.district_list,
    }),

    tableData() {
      return this.customSort(this.districtsList, 'description')
    },

    headers() {
      return [
        { text: "road_network.region", value: "region_description", sortable: true },
        { text: "stdCols.name", value: "description", sortable: true },
        { text: "stdCols.type", value: "type", sortable: true },
        { text: "stdCols.notes", value: "notes", sortable: false },
      ]
    },
  },

  watch: {
    selectedRegion(newVal) {
      this.payload = { regionId: newVal }
      this.reloadData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>